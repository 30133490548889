import store from "@/store";
import config from "@/config";
import {
  CLIENT_ADMIN,
  LENDFLOW_ROLE_GROUP,
  SUPER_ADMIN
} from "@/helpers/constants";
import { ROUTE_PAGE_NOT_FOUND } from "@/router/routes";
import type { NavigationGuardWithThis } from "vue-router";

export const routeAccessCheck = () => {
  const user = store.state.auth.user;
  const authClientSettings = store.state.auth.authClientSettings;
  const roles = user?.roles || [];

  const canClientRedirectToBorrowerPlatform =
    authClientSettings?.is_redirect_to_borrower_platform_enabled;

  const isClientAccelerateTax =
    authClientSettings?.id === config.other.accelerateTaxClientId;

  const canUserRedirectToContractBuilder =
    (authClientSettings?.can_manage_docusign && roles.includes(CLIENT_ADMIN)) ||
    roles.some((role) => LENDFLOW_ROLE_GROUP.includes(role));

  const isClientAdmin = roles.includes(CLIENT_ADMIN);
  const isSuperAdmin = roles.includes(SUPER_ADMIN);

  return {
    canClientRedirectToBorrowerPlatform,
    canUserRedirectToContractBuilder,
    isClientAccelerateTax,
    isClientAdmin,
    isSuperAdmin
  };
};

export const mockedFeatureRouteMiddleware: NavigationGuardWithThis<unknown> = (
  _,
  __,
  next
) => {
  if (config.includeMocks) {
    next();
  } else {
    next({ name: ROUTE_PAGE_NOT_FOUND });
  }
};
