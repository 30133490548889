export enum Ability {
  answer = "answer",
  create = "create",
  decline = "decline",
  delete = "delete",
  duplicate = "duplicate",
  manageFastTrack = "manageFastTrack",
  manageFunders = "manageFunders",
  manageLenderNetwork = "manageLenderNetwork",
  moveStages = "moveStages",
  run = "run",
  runAny = "runAny",
  update = "update",
  updateBillingType = "updateBillingType",
  updateManagedByLendflow = "updateManagedByLendflow",
  updatePrimaryScorecard = "updatePrimaryScoreCard",
  updatePrimaryScorecardGroup = "updatePrimaryScoreCardGroup",
  updateRelationship = "updateRelationship",
  updateStatus = "updateStatus",
  view = "view",
  viewAny = "viewAny",
  viewBillingType = "viewBillingType",
  viewManagedByLendflow = "viewManagedByLendflow",
  viewFastTrack = "viewFastTrack"
}

export enum PermissionSubject {
  application = "Application",
  applicationImport = "ApplicationImport",
  applicationNote = "ApplicationNote",
  bankAccount = "BankAccount",
  business = "Business",
  businessNote = "BusinessNote",
  businessProfile = "BusinessProfile",
  client = "Client",
  consentTemplate = "ConsentTemplate",
  dataOrchestrationLog = "DataOrchestrationLog",
  dataOrchestrationTemplate = "DataOrchestrationTemplate",
  discoveryCallQuestion = "DiscoveryCallQuestion",
  documentVerification = "DocumentVerification",
  docusignTemplate = "DocusignTemplate",
  file = "File",
  filter = "Filter",
  funder = "Funder",
  fundingTransaction = "FundingTransaction",
  individualProfile = "IndividualProfile",
  lenderPlacement = "LenderPlacement",
  notification = "Notification",
  ocrolusBook = "OcrolusBook",
  offer = "Offer",
  offerNote = "OfferNote",
  ownershipSnapshot = "OwnershipSnapshot",
  permissionsPreset = "PermissionsPreset",
  scoreCard = "ScoreCard",
  scoreCardBacktestReport = "ScoreCardBacktestReport",
  scoreCardGroup = "ScoreCardGroup",
  workflowTemplate = "WorkflowTemplate"
}

export enum UserType {
  lendflow = "lendflow",
  client = "client"
}
