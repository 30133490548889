import { type defineComponent } from "vue";

import type { WorkflowBlockIds } from "@/models/workflows";
import { getAsyncComponent } from "@/hooks/common";

const AccountsPayable = getAsyncComponent(
  () => import("@/components/deals/AccountsPayable.vue")
);
const AdditionalAffiliatedBusinessInfo = getAsyncComponent(
  () => import("@/views/deals/components/AdditionalAffiliatedBusinessInfo.vue")
);
const AdditionalBusinessInfo = getAsyncComponent(
  () => import("@/views/deals/components/AdditionalBusinessInfo.vue")
);
const AdditionalInfoBlock = getAsyncComponent(
  () => import("@/components/deals/AdditionalOwnerInfo.vue")
);
const AffiliatedBusinessInfo = getAsyncComponent(
  () => import("@/views/deals/components/AffiliatedBusinessInfo.vue")
);
const AffiliatedIndividuals = getAsyncComponent(
  () => import("@/views/deals/components/AffiliatedIndividuals.vue")
);
const AffiliatedIndividualsAdditionalInformation = getAsyncComponent(
  () =>
    import(
      "@/views/deals/components/AffiliatedIndividualsAdditionalInformation.vue"
    )
);
const BondingAgentCard = getAsyncComponent(
  () => import("@/views/deals/components/BondingAgentCard.vue")
);
const BusinessNotes = getAsyncComponent(
  () => import("@/views/deals/components/BusinessNotes.vue")
);
const ClearAdverseMedia = getAsyncComponent(
  () => import("@/components/deals/dataServices/adverseMedia/Clear.vue")
);
const ClearBankrupctyPerson = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/ClearBankruptcy/ClearBankruptcyPerson.vue"
    )
);
const ClearBusiness = getAsyncComponent(
  () => import("@/components/deals/dataServices/liens/ClearBusiness.vue")
);
const ClearBusinessLiens = getAsyncComponent(
  () => import("@/components/deals/dataServices/liens/ClearBusiness.vue")
);
const ClearBusinessLitigation = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/litigation/clear/LitigationBusiness.vue"
    )
);
const ClearBusinessUccs = getAsyncComponent(
  () => import("@/components/deals/dataServices/ClearBusiness.vue")
);
const ClearCourtSearch = getAsyncComponent(
  () => import("@/components/deals/dataServices/litigation/CourtSearch.vue")
);
const ClearIndividualLitigation = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/litigation/clear/LitigationIndividual.vue"
    )
);
const ClearIndividualUccs = getAsyncComponent(
  () => import("@/components/deals/dataServices/ucc/Clear.vue")
);
const ClearKyb = getAsyncComponent(
  () => import("@/components/deals/dataServices/kyb/ClearKyb.vue")
);
const ClearKyc = getAsyncComponent(
  () => import("@/components/deals/dataServices/kyc/ClearKyc.vue")
);
const ClearLiens = getAsyncComponent(
  () => import("@/components/deals/dataServices/liens/ClearLiens.vue")
);
const ClosingOffers = getAsyncComponent(
  () => import("@/views/deals/components/ClosingOffers.vue")
);
const Codat = getAsyncComponent(
  () => import("@/views/deals/components/Codat.vue")
);
const CompanyDebts = getAsyncComponent(
  () => import("@/views/deals/components/CompanyDebts.vue")
);
const CompanyOfficers = getAsyncComponent(
  () => import("@/views/deals/components/CompanyOfficers.vue")
);
const CustomAttributes = getAsyncComponent(
  () => import("@/views/deals/components/customAttributes/CustomAttributes.vue")
);
const CustomerInformation = getAsyncComponent(
  () => import("@/views/deals/components/CustomerInformation.vue")
);
const DiscoveryQuestions = getAsyncComponent(
  () => import("@/views/deals/activity/DiscoveryCallQuestions.vue")
);
const DnBBusinessCredit = getAsyncComponent(
  () => import("@/components/deals/dataServices/Dnb.vue")
);
const DnBBusinessMatch = getAsyncComponent(
  () => import("@/components/deals/dataServices/matchDnb/MatchDnb.vue")
);
const DnBCompanyInformation = getAsyncComponent(
  () =>
    import("@/components/deals/dataServices/kyb/dnb/DnBCompanyInformation.vue")
);
const Ekata = getAsyncComponent(
  () => import("@/views/deals/components/Ekata.vue")
);
const EquipmentDetails = getAsyncComponent(
  () => import("@/views/deals/components/EquipmentDetails.vue")
);
const Equifax = getAsyncComponent(
  () =>
    import("@/components/deals/dataServices/personalCredit/equifax/Equifax.vue")
);
const ExperianBankruptcies = getAsyncComponent(
  () => import("@/components/deals/dataServices/bankruptcies/Experian.vue")
);
const ExperianBusinessCredit = getAsyncComponent(
  () => import("@/components/deals/dataServices/experian/ExperianBC.vue")
);
const ExperianBusinessMatch = getAsyncComponent(
  () =>
    import("@/components/deals/dataServices/matchExperian/MatchExperian.vue")
);
const ExperianQuickSearch = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/matchExperian/ExperianQuickSearchWrapper.vue"
    )
);
const ExperianGdn = getAsyncComponent(
  () => import("@/components/deals/dataServices/experian/ExperianGDN.vue")
);
const ExperianJudgments = getAsyncComponent(
  () =>
    import("@/components/deals/dataServices/judgments/experian/Experian.vue")
);
const ExperianFacts = getAsyncComponent(
  () => import("@/components/deals/dataServices/experian/kyb/ExperianFacts.vue")
);
const ExperianLiens = getAsyncComponent(
  () => import("@/components/deals/dataServices/liens/ExperianLiens.vue")
);
const ExperianPersonalCredit = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/experian/ExperianPersonalCredit.vue"
    )
);
const ExperianUccs = getAsyncComponent(
  () => import("@/components/deals/dataServices/ucc/Experian.vue")
);
const ExperianBusinessContacts = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/experian/kyb/ExperianBusinessContacts.vue"
    )
);
const ExperianCorporateRegistrations = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/experian/kyb/ExperianCorporateRegistrations.vue"
    )
);
const ExperianLegalCollections = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/experian/kyb/ExperianLegalCollections.vue"
    )
);
const ExperianCommercialCollections = getAsyncComponent(
  () =>
    import("@/components/deals/dataServices/experian/CommercialCollections.vue")
);
const ExperianCreditStatus = getAsyncComponent(
  () => import("@/components/deals/dataServices/experian/CreditStatus.vue")
);
const FraudSentilink = getAsyncComponent(
  () => import("@/views/deals/components/FraudSentilink.vue")
);
const FundingDetails = getAsyncComponent(
  () => import("@/components/deals/workflow/CompletedStage/FundingDetails.vue")
);
const FundingInfo = getAsyncComponent(
  () => import("@/components/FundingInfo.vue")
);
const FundingSummary = getAsyncComponent(
  () => import("@/components/deals/workflow/CompletedStage/FundingSummary.vue")
);
const Guarantor = getAsyncComponent(
  () => import("@/views/deals/components/equipmentRental/GuarantyCard.vue")
);
const Heron = getAsyncComponent(
  () => import("@/components/deals/dataServices/Heron.vue")
);
const Inscribe = getAsyncComponent(
  () => import("@/components/deals/dataServices/Inscribe.vue")
);
const InsuranceInformation = getAsyncComponent(
  () => import("@/views/deals/components/InsuranceInformation.vue")
);
const LexisNexisBankruptcy = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/bankruptcies/lexisNexis/LexisNexis.vue"
    )
);
const LexisNexisJudgments = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/judgments/lexisNexis/LexisNexis.vue"
    )
);
const LexisNexisKyb = getAsyncComponent(
  () => import("@/components/deals/dataServices/kyb/LexisNexis.vue")
);
const LexisNexisKyc = getAsyncComponent(
  () => import("@/components/deals/dataServices/kyc/lexisNexis/LexisNexis.vue")
);
const LexisNexisLiens = getAsyncComponent(
  () => import("@/components/deals/dataServices/liens/LexisNexisLiens.vue")
);
const LexisNexisUccs = getAsyncComponent(
  () => import("@/views/deals/uccs/LexisNexisUcc.vue")
);
const LoanServicing = getAsyncComponent(
  () => import("@/components/deals/closing/SubmitToServicer.vue")
);
const MatchClearBusiness = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/matchClear/business/MatchClearBusiness.vue"
    )
);
const MatchClearIndividual = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/matchClear/individual/MatchClearIndividual.vue"
    )
);
const MatchSentilink = getAsyncComponent(
  () =>
    import("@/components/deals/dataServices/matchSentilink/MatchSentilink.vue")
);
const MiddeskBankruptcy = getAsyncComponent(
  () => import("@/components/deals/dataServices/bankruptcies/Middesk.vue")
);
const MiddeskKyb = getAsyncComponent(
  () => import("@/components/deals/dataServices/kyb/Middesk.vue")
);
const Ocrolus = getAsyncComponent(
  () => import("@/components/deals/dataServices/cfa/ocrolus/Ocrolus.vue")
);
const OfferDetails = getAsyncComponent(
  () => import("@/components/deals/workflow/CompletedStage/OfferDetails.vue")
);
const Persona = getAsyncComponent(
  () => import("@/components/deals/dataServices/Persona.vue")
);
const PersonalInfo = getAsyncComponent(
  () => import("@/views/deals/components/OwnersPersonalInfo.vue")
);
const Plaid = getAsyncComponent(
  () => import("@/views/deals/components/bankData/Plaid.vue")
);
const PrequalDetails = getAsyncComponent(
  () => import("@/views/deals/components/PrequalDetails.vue")
);
const PrimaryBusiness = getAsyncComponent(
  () => import("@/views/deals/components/PrimaryBusinessInfo.vue")
);
const Railz = getAsyncComponent(
  () => import("@/views/deals/components/Railz.vue")
);
const SignedDealsParent = getAsyncComponent(
  () => import("@/views/deals/components/SignedDealsParent.vue")
);
const SocialScoring = getAsyncComponent(
  () => import("@/components/deals/dataServices/SocialScoring.vue")
);
const SocureFraud = getAsyncComponent(
  () => import("@/views/deals/components/SocureFraud.vue")
);
const SocureKYC = getAsyncComponent(
  () => import("@/views/deals/components/Socure.vue")
);
const StipsCollection = getAsyncComponent(
  () => import("@/views/deals/components/StipsCollection.vue")
);
const TradeReferences = getAsyncComponent(
  () =>
    import("@/views/deals/components/equipmentRental/TradeReferencesCard.vue")
);

const MoneyThumb = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/cfa/moneythumb/MoneyThumbService.vue"
    )
);

const BluCognition = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/documentVerification/BluCognition.vue"
    )
);

const TaxStatusKyb = getAsyncComponent(
  () => import("@/components/deals/dataServices/kyb/taxStatus/TaxStatusKyb.vue")
);

const TaxStatusKyc = getAsyncComponent(
  () => import("@/components/deals/dataServices/kyc/taxStatus/TaxStatusKyc.vue")
);

const MiddeskBusinessMatch = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/businessMatch/middesk/MiddeskBusinessMatch.vue"
    )
);

const EnigmaBusinessMatch = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/businessMatch/enigma/EnigmaBusinessMatch.vue"
    )
);

const ExperianFraud = getAsyncComponent(
  () =>
    import("@/components/deals/dataServices/experian/fraud/ExperianFraud.vue")
);

const TransUnionPersonalCredit = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/personalCredit/transunion/TransUnion.vue"
    )
);

const LexisNexisBusinessCredit = getAsyncComponent(
  () =>
    import(
      "@/components/deals/dataServices/businessCredit/lexisNexis/LexisNexis.vue"
    )
);

const EnigmaKyb = getAsyncComponent(
  () => import("@/components/deals/dataServices/kyb/enigma/EnigmaKyb.vue")
);

export type WorkflowBlockComponentsKey = Exclude<
  WorkflowBlockIds,
  | "bank_account_data"
  | "bank_data"
  | "business_extra"
  | "middesk"
  | "monthly_balance"
  | "transactions"
>;
// Please keep the keys sorted alphabetically
const WORKFLOW_BLOCK_COMPONENTS: Record<
  WorkflowBlockComponentsKey,
  ReturnType<typeof getAsyncComponent> | ReturnType<typeof defineComponent>
> = {
  //Application
  accounts_payable: AccountsPayable,
  additional_business: AdditionalBusinessInfo,
  additional_owner: AdditionalInfoBlock,
  affiliated_businesses: AffiliatedBusinessInfo,
  affiliated_businesses_additional_information:
    AdditionalAffiliatedBusinessInfo,
  affiliated_individuals: AffiliatedIndividuals,
  affiliated_individuals_additional_information:
    AffiliatedIndividualsAdditionalInformation,
  bank_data_moneythumb: MoneyThumb,
  bank_data_ocrolus: Ocrolus,
  bank_data_plaid: Plaid,
  bonding_agent: BondingAgentCard,
  business: PrimaryBusiness,
  business_notes: BusinessNotes,
  company_debts: CompanyDebts,
  company_officers: CompanyOfficers,
  custom_attributes: CustomAttributes,
  customer_information: CustomerInformation,
  discovery: DiscoveryQuestions,
  equipment_details: EquipmentDetails,
  funding: FundingInfo,
  guarantor: Guarantor,
  insurance: InsuranceInformation,
  personal: PersonalInfo,
  prequal: PrequalDetails,
  signed_application: SignedDealsParent,
  trade_references: TradeReferences,

  //Underwriting
  blu_cognition: BluCognition,
  clear_adverse_media: ClearAdverseMedia,
  clear_bankruptcies: ClearBankrupctyPerson,
  clear_business_litigation: ClearBusinessLitigation,
  clear_business_match: MatchClearBusiness,
  clear_business_uccs: ClearBusinessUccs,
  clear_court_search: ClearCourtSearch,
  clear_individual_litigation: ClearIndividualLitigation,
  clear_individual_match: MatchClearIndividual,
  clear_individual_uccs: ClearIndividualUccs,
  clear_kyb: ClearKyb,
  clear_kyc: ClearKyc,
  clear_liens: ClearBusinessLiens,
  clear_liens_business: ClearBusiness,
  clear_liens_individual: ClearLiens,
  codat: Codat,
  dnb_business_credit: DnBBusinessCredit,
  dnb_business_match: DnBBusinessMatch,
  dnb_company_information_kyb: DnBCompanyInformation,
  ekata: Ekata,
  equifax_business_credit: Equifax,
  experian_business_contacts: ExperianBusinessContacts,
  experian_corporate_registrations: ExperianCorporateRegistrations,
  experian_legal_collections: ExperianLegalCollections,
  experian_bankruptcies: ExperianBankruptcies,
  experian_business_credit: ExperianBusinessCredit,
  experian_business_match: ExperianBusinessMatch,
  experian_business_quick_search: ExperianQuickSearch,
  experian_gdn_business_credit: ExperianGdn,
  experian_judgments: ExperianJudgments,
  experian_kyb: ExperianFacts,
  experian_liens: ExperianLiens,
  experian_personal_credit: ExperianPersonalCredit,
  experian_uccs: ExperianUccs,
  experian_commercial_collections: ExperianCommercialCollections,
  experian_credit_status: ExperianCreditStatus,
  experian_fraud: ExperianFraud,
  heron: Heron,
  inscribe: Inscribe,
  lexisnexis_bankruptcies: LexisNexisBankruptcy,
  lexisnexis_judgments: LexisNexisJudgments,
  lexisnexis_kyb: LexisNexisKyb,
  lexisnexis_kyc: LexisNexisKyc,
  lexisnexis_liens: LexisNexisLiens,
  lexisnexis_uccs: LexisNexisUccs,
  middesk_bankruptcies: MiddeskBankruptcy,
  middesk_business_search: MiddeskBusinessMatch,
  middesk_kyb: MiddeskKyb,
  moneythumb: MoneyThumb,
  ocrolus: Ocrolus,
  ocrolus_doc_verification: Ocrolus,
  persona: Persona,
  plaid: Plaid,
  railz: Railz,
  scorely: SocialScoring,
  sentilink_fraud: FraudSentilink,
  sentilink_match: MatchSentilink,
  socure_fraud: SocureFraud,
  socure_kyc: SocureKYC,
  tax_status_kyb: TaxStatusKyb,
  tax_status_kyc: TaxStatusKyc,
  enigma_business_match: EnigmaBusinessMatch,
  enigma_kyb: EnigmaKyb,
  transunion_personal_credit: TransUnionPersonalCredit,
  lexis_nexis_business_credit: LexisNexisBusinessCredit,

  //Closing
  loan_servicing: LoanServicing,
  offers: ClosingOffers,
  stips: StipsCollection,

  //Funded
  funding_details: FundingDetails,
  funding_summary: FundingSummary,
  offer_details: OfferDetails
};

export default WORKFLOW_BLOCK_COMPONENTS;
