import {
  COLORS,
  SCORECARD_COLOR_SWATCHES,
  SERVICE_APPLICATION_DATA,
  SERVICE_CUSTOM_ATTRIBUTES,
  ATTRIBUTE_SYMBOLS,
  SCORECARD_STATUS_KNOCKOUT,
  SERVICE_CUSTOM
} from "@/helpers/constants";
import type {
  IScorecard,
  IScorecardAttribute,
  IScorecardCategory,
  IScorecardContentCategory,
  IScorecardGroup,
  IScorecardGroupResult,
  IScorecardResult,
  IScorecardRiskTier,
  IScorecardStatus,
  ScorecardAttributeValueType,
  ScorecardForGroupTable,
  BacktestReport,
  GroupBacktestReport,
  IScorecardContentCondition
} from "@/models/scorecards";
import { hexToRgb } from "@/helpers/formatting";
import type {
  IAttribute,
  ICustomAttributeContext,
  IDataService,
  IRules,
  IStep
} from "@/models/orchestration";
import round from "lodash/round";
import type { IPaginatedResponse } from "@/models/common";
import { v4 as uuidv4 } from "uuid";
import get from "lodash/get";
import isObject from "lodash/isObject";
import cloneDeepWith from "lodash/cloneDeepWith";
import isEmpty from "lodash/isEmpty";

export const getScorecardCategoryBlueprint = (
  service: IScorecardAttribute | IScorecardCategory
): IScorecardCategory => {
  return {
    category_id:
      service.id === SERVICE_CUSTOM && service.context?.value?.id
        ? service.context?.value?.id
        : uuidv4(),
    id: service.id,
    name: service.name,
    rules: service.rules,
    options: service.options,
    service_name: service.service_name,
    service_id: service.service_id,
    service_id_number: service.service_id_number,
    color: service.color,
    conditions: service.conditions,
    points: service.points || 0,
    locked: service.locked || false,
    automatic_distribution: service.automatic_distribution || false,
    above_max_value: service.above_max_value || false,
    reverse_range: service.reverse_range || false,
    knockout: false,
    context: service.context,
    accepts_restrictions: service.accepts_restrictions
  };
};

export const expandContentCategory = (
  category: IScorecardContentCategory,
  index: number,
  services: IDataService[] | null
): IScorecardCategory => {
  const serviceInfo: {
    id: number | string;
    name: string;
    color?: string | null;
    accepts_restrictions: boolean;
  } = { id: 0, name: "", color: null, accepts_restrictions: false };
  let categoryService: Partial<IAttribute> = {};

  const isCustom = category.attribute === SERVICE_CUSTOM;

  services?.forEach((service) => {
    const categoryServiceAttribute = service.attributes?.find(
      ({ context, id }) =>
        isCustom
          ? context?.value?.id === category.context?.value?.id
          : id === category.attribute
    );
    if (
      (
        [SERVICE_APPLICATION_DATA, SERVICE_CUSTOM_ATTRIBUTES] as Array<string>
      ).includes(service.title) &&
      !!categoryServiceAttribute
    ) {
      serviceInfo.id = service.title;
      serviceInfo.name = service.title;
      switch (service.title) {
        case SERVICE_APPLICATION_DATA:
          categoryService = categoryServiceAttribute;
          serviceInfo.color = categoryServiceAttribute.color ?? null;
          return;
        case SERVICE_CUSTOM_ATTRIBUTES:
          categoryService =
            service.attributes?.find(
              (attribute) =>
                (attribute.context?.value as ICustomAttributeContext).id ===
                (category.context?.value as ICustomAttributeContext).id
            ) || {};
          return;
      }
    } else if (service.data_service_details) {
      Object.entries(service.data_service_details).forEach(
        ([dataKey, dataService]) => {
          Object.entries(dataService).forEach(([key, value]) => {
            value.forEach((s) => {
              if (s.id === category.attribute) {
                serviceInfo.id = Number(dataKey);
                const name = key.includes("Plaid")
                  ? key
                  : service.services?.find(
                      (service) => service.id === Number(dataKey)
                    )?.name;
                serviceInfo.name = name || key;
                categoryService = s;
                serviceInfo.color = s.color ?? null;
                serviceInfo.accepts_restrictions = s.accepts_restrictions;
              }
            });
          });
        }
      );
    }
  });

  const backups = category.backups?.map((backup, backupIndex) => {
    return expandContentCategory(backup, backupIndex, services);
  });
  return {
    ...category,
    category_id:
      (isCustom ? category.context?.value?.id : category.category_id) ||
      uuidv4(),
    id: category.attribute || "",
    // get name from services
    name: categoryService?.name || "",
    options: categoryService.options,
    rules: categoryService.rules,
    service_id: categoryService.service_id,
    service_id_number: serviceInfo.id,
    knockout: !!category.knockout,
    service_name: serviceInfo.name,
    accepts_restrictions: serviceInfo.accepts_restrictions,
    color:
      serviceInfo.color ??
      SCORECARD_COLOR_SWATCHES[index % SCORECARD_COLOR_SWATCHES.length],
    backups
  };
};

export const getColorId = (color: string) => {
  if (!color) {
    return null;
  }
  return Object.keys(COLORS).find(
    (key) => COLORS[parseInt(key)] === hexToRgb(color)
  );
};

export const getPoints = (scorecard: IScorecard | IScorecardGroup) => {
  const points = scorecard.results?.[0]?.points || 0;
  const total =
    "group_points" in scorecard
      ? scorecard.group_points || 1
      : scorecard.points || 1;
  return `${points} ⁄ ${total}`;
};

export const distributePoints = (
  totalPoints: number,
  length: number,
  floor = 0,
  float = true
): number[] => {
  const points = [];
  let remain = totalPoints;
  let elements = length;
  let i = 0;
  while (remain > floor) {
    // updated to use decimals
    const distributed = float
      ? round(remain / elements, 2)
      : Math.ceil(remain / elements);
    points[i++] = distributed;
    remain -= distributed;
    elements--;
  }
  return points;
};

export const modelIsScorecardGroup = (
  model: IScorecard | IScorecardGroup
): model is IScorecardGroup => {
  return "score_cards" in model;
};

export const evenlyDivideValues = (
  startValue: number,
  endValue: number,
  length: number,
  float = true
): number[] => {
  const distributed = distributePoints(endValue - startValue, length, 0, float);
  return distributed.reduce(
    (acc, curr) => {
      return [...acc, acc[acc.length - 1] + curr];
    },
    [startValue]
  );
};

export const cleanCategories = (
  categories: IScorecardCategory[],
  redistributePoints = false,
  isBackup = false,
  parentPoints = 0
): IScorecardContentCategory[] => {
  return categories.map((category) => {
    if (category.distribution) {
      if (redistributePoints) {
        // only redistribute if we have to from "choose attributes"
        const points = category.distribution.reduce(
          (prev, curr) => prev + curr.points,
          0
        );
        if (points !== category.points) {
          const newPoints = distributePoints(
            category.points,
            category.distribution.length
          );
          category.distribution = category.distribution.map(
            (distribution, index) => {
              return {
                min: distribution.min,
                max: distribution.max,
                points: newPoints[index]
              };
            }
          );
        }
      } else {
        // remap distribution object
        category.distribution = category.distribution.map((distribution) => {
          return {
            min: distribution.min,
            max: distribution.max,
            points: distribution.points
          };
        });
      }
    }
    return getReturnValue(category, redistributePoints, isBackup, parentPoints);
  });
};

const getReturnValue = (
  category: IScorecardCategory,
  redistributePoints: boolean,
  isBackup: boolean,
  parentPoints: number
) => {
  return {
    attribute: category.id,
    above_max_value: category.above_max_value,
    automatic_distribution: category.automatic_distribution,
    points: redistributePoints && isBackup ? parentPoints : category.points,
    reverse_range: category.reverse_range,
    locked: category.locked,
    conditions: category.conditions || [],
    distribution: category.distribution || undefined,
    context: category.context || undefined,
    color: category.color || null,
    backups: isBackup
      ? undefined
      : cleanCategories(
          category.backups || [],
          redistributePoints,
          true,
          category.points
        ),
    knockout: category.knockout,
    knockout_conditions: category.knockout_conditions || undefined,
    restrict_by: category.restrict_by || undefined
  };
};

export const getColorFromIndex = (index: number) => {
  return SCORECARD_COLOR_SWATCHES[index % SCORECARD_COLOR_SWATCHES.length];
};

// scorecard reports
export const getStatuses = (
  scorecardOrScorecardGroup: IScorecard | IScorecardGroup | null
) =>
  scorecardOrScorecardGroup?.statuses?.filter(
    (status) => status.points_from !== null && status.points_to !== null
  ) || [];

export const getStatusOptions = (statuses: IScorecardStatus[]) =>
  Object.fromEntries(
    Object.values(statuses).map((status) => [status.id, status.name])
  );

export const getStatusesWithDealCount = (
  report:
    | IPaginatedResponse<
        | IScorecardResult
        | IScorecardGroupResult
        | BacktestReport
        | GroupBacktestReport
      >
    | undefined,
  activeStatuses: IScorecardStatus[]
) => {
  if (!report) {
    return [];
  }
  activeStatuses.sort((a, b) => {
    if (a.points_from !== null && b.points_from !== null) {
      return a.points_from < b.points_from ? -1 : 1;
    }
    return 0;
  });

  const reportData = report !== null && "data" in report ? report.data : report;

  return activeStatuses?.map((status) => {
    const count =
      reportData?.filter(
        (
          data:
            | IScorecardResult
            | IScorecardGroupResult
            | BacktestReport
            | GroupBacktestReport
        ) => {
          if ("score_card_group_status" in data) {
            return data.score_card_group_status?.id === status.id;
          }
          return data.score_card_status?.id === status.id;
        }
      ).length || 0;
    return {
      ...status,
      deals_count: count
    };
  });
};

export const getRiskTiers = (
  scorecardOrScorecardGroup: IScorecard | IScorecardGroup | null
) =>
  scorecardOrScorecardGroup?.risk_tiers?.filter(
    (tier) => tier.points_from !== null && tier.points_to !== null
  ) || [];

export const getRiskTiersOptions = (riskTiers: IScorecardRiskTier[]) =>
  Object.fromEntries(
    Object.values(riskTiers).map((riskTier) => [riskTier.id, riskTier.name])
  );

export const getRiskTiersWithDealCount = (
  report:
    | IPaginatedResponse<
        | IScorecardResult
        | IScorecardGroupResult
        | BacktestReport
        | GroupBacktestReport
      >
    | undefined,
  activeRiskTiers: IScorecardRiskTier[]
) => {
  if (!report) {
    return [];
  }
  activeRiskTiers.sort((a, b) => {
    if (a.points_from !== null && b.points_from !== null) {
      return a.points_from < b.points_from ? -1 : 1;
    }
    return 0;
  });
  const reportData = report !== null && "data" in report ? report.data : report;

  return activeRiskTiers?.map((riskTiers) => {
    const count =
      reportData?.filter(
        (
          data:
            | IScorecardResult
            | IScorecardGroupResult
            | BacktestReport
            | GroupBacktestReport
        ) => {
          if ("score_card_group_risk_tier" in data) {
            return data.score_card_group_risk_tier?.id === riskTiers.id;
          }
          return data.score_card_risk_tier?.id === riskTiers.id;
        }
      ).length || 0;
    return {
      ...riskTiers,
      deals_count: count
    };
  });
};

export const parseDecimalNumberToFixed = (num: number, digits = 2) =>
  Number.parseFloat(Number.parseFloat(String(num)).toFixed(digits));

export const checkValueType = (
  rules: IRules,
  type: ScorecardAttributeValueType
) => rules.value.includes(type);

export const getScorecardIsKnockedOut = (
  result: ScorecardForGroupTable | IScorecardResult | undefined
) => {
  if (!result) {
    return false;
  }
  const { knockouts, knocked_out_at } = result;
  return !!knockouts?.length && !!knocked_out_at;
};

export const formatKnockoutReasons = (
  serviceName: string,
  conditions?: IScorecardContentCondition[],
  useServiceName = true
) => {
  if (!conditions?.[0]?.type) {
    return "";
  }
  const prefix = useServiceName ? `• ${serviceName}` : "";
  const isBoolean = conditions?.[0].type === "boolean";
  if (isBoolean) {
    return `${prefix} is ${conditions?.[0].value}`;
  }
  const isNull = conditions?.[0].type === "is_null";
  if (isNull) {
    return `${prefix} is null`;
  }
  const isRange =
    conditions?.[0].type === "greater_than_or_equal" &&
    conditions?.[1]?.type === "less_than_or_equal";
  if (isRange) {
    return `${prefix} >= ${conditions?.[0].value} and <= ${conditions?.[1].value}`;
  }

  const symbol = ATTRIBUTE_SYMBOLS[conditions[0].type];
  return `${prefix} ${symbol} ${conditions?.[0].value}`;
};

export const getHighLevelFilteredSections = (
  sections: IDataService[],
  search: string
) =>
  sections?.filter((section) => {
    const sectionTitleIncludesSearch = section.title
      .toLowerCase()
      .includes(search.toLowerCase());

    const sectionServiceIncludesSearch =
      section.services?.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      ).length ||
      // Application Data & Custom Data have different structure
      section.attributes?.some(
        ({ name }) => name?.toLowerCase().includes(search.toLowerCase())
      );

    const sectionAttributesIncludesSearch = Object.values(
      section.data_service_details || {}
    ).some(
      (service) =>
        Object.keys(service || {}).some((key) =>
          key.toLowerCase().includes(search.toLowerCase())
        ) ||
        Object.values(service || {}).some((attributes) =>
          attributes.some(({ name }) =>
            name.toLowerCase().includes(search.toLowerCase())
          )
        )
    );
    return (
      section.title.toLowerCase() !== "trigger" &&
      (sectionTitleIncludesSearch ||
        sectionServiceIncludesSearch ||
        sectionAttributesIncludesSearch)
    );
  });

export const collectionHasTransunion = <TIdentifiable extends { id: string }>(
  attr: TIdentifiable[] | undefined
) => {
  if (!attr) {
    return false;
  }
  return attr.some(({ id }) => id.includes("transunion"));
};

export const updateTransUnionAttributes = (
  activeTemplateSteps: IStep[]
): IStep[] => {
  const activeTemplateModels = new Set();

  activeTemplateSteps.forEach(({ attributes, outcomes }) => {
    if (collectionHasTransunion(attributes)) {
      const models = outcomes.flatMap(
        ({ checks }) =>
          checks?.flatMap(
            ({ conditions }) =>
              conditions?.flatMap(({ context }) =>
                get(context, "value.models", [])
              )
          )
      );
      models.forEach((model) => activeTemplateModels.add(model));
    }
  });

  return activeTemplateSteps.map((step) => {
    const isTransUnion = collectionHasTransunion(step.attributes);

    if (!isTransUnion) {
      return step;
    }

    return cloneDeepWith(
      step,
      (
        value: Record<string, string> | unknown,
        _,
        __,
        stack: { size?: number }
      ) => {
        if (isObject(value) && stack?.size && get(value, "models")) {
          return {
            models: Array.from(activeTemplateModels)
          };
        }
      }
    );
  });
};

export const isDoScBusinessZipCodeAttribute = (
  serviceId: string | undefined,
  id: string
) => serviceId === "application" && id === "business_zip_code";

export const hasKnockout = ({ results }: IScorecard) =>
  !!results?.[0]?.categories?.some(({ knockout }) => knockout);

export const isKnockoutTriggered = ({ results }: IScorecard) =>
  !!results?.[0]?.knocked_out_at && !!results?.[0]?.knockouts?.length;

export const getResults = (scorecard: IScorecard) => {
  return scorecard?.results || [];
};

export const getGroupResults = (scorecard: IScorecardGroup) => {
  return scorecard?.results || [];
};

export const isGroupKnockoutTriggered = (scorecard: IScorecardGroup) => {
  const results = scorecard.results || [];
  if (!isEmpty(results[0]?.score_card_results[0]?.categories[0])) {
    return results[0].score_card_results.some(
      (result) =>
        result.status === SCORECARD_STATUS_KNOCKOUT &&
        result.knocked_out_at !== null
    );
  }
  return null;
};
