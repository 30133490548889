import { createStore } from "vuex";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import { auth } from "./modules/authentication";
import { options } from "./modules/options";
import { applications } from "./modules/applications";
import { funders } from "./modules/funders";
import { businesses } from "./modules/businesses";
import { clients } from "./modules/clients";
import { dashboard } from "./modules/dashboard";
import { stips } from "./modules/stips";
import { servicing } from "./modules/servicing";
import { orchestration } from "./modules/orchestration";
import { workflows } from "./modules/workflows";
import { scorecards } from "./modules/scorecards";
import { profiles } from "./modules/profiles/index";
import { underwriting } from "./modules/underwriting";
import type {
  HistoryRoute,
  HistoryStack,
  IDealHistoryRecord,
  IRootState,
  IGlobalState,
  ILfVuexStore
} from "@/models/state";

import { HISTORY_SECTIONS } from "@/helpers/constants";
import { Toast, type ToastMessage, type ToastMessageData } from "@/lib/Toast";
import type { RouteLocationNormalizedLoaded } from "vue-router";
import type { AdvancedSearchFilter, IApplication } from "@/models/applications";
import type { IDashboardSettings } from "@/models/common";

const initialHistoryStack: HistoryStack = {
  deals: [],
  advancedSearches: []
};

const maxStackSize = 10;

const state: IGlobalState = {
  redirectTo: "",
  globalMessages: new Toast(),
  globalLoader: false,
  preventGlobalMessage: false,
  previousFullRoute: null,
  historyStack: localStorage.getItem("historyStack")
    ? JSON.parse(localStorage.getItem("historyStack") as string)
    : initialHistoryStack
};

const mutations: MutationTree<IRootState> = {
  setRedirectTo(state, value: string) {
    state.redirectTo = value;
  },
  setGlobalMessage(state, data: ToastMessageData) {
    if (state.preventGlobalMessage) {
      return;
    }
    state.globalMessages.addMessage(data);
  },
  clearGlobalMessage(state, id: ToastMessage["id"]) {
    state.globalMessages.removeMessage(id);
  },
  clearAllGlobalMessages(state) {
    state.globalMessages.removeAllMessages();
  },
  setGlobalLoader(state, value: boolean) {
    state.globalLoader = value;
  },
  setPreviousFullRoute(state, value: RouteLocationNormalizedLoaded) {
    state.previousFullRoute = value;
  },
  addEntryToHistoryStack(state, currentRoute: HistoryRoute) {
    if (currentRoute.section === HISTORY_SECTIONS.DEALS) {
      const routeId = state.historyStack?.[currentRoute.section].findIndex(
        (route) => route.id === currentRoute.value.id
      );

      // remove deal route if already on stack to not make duplications
      if (routeId >= 0) {
        state.historyStack[currentRoute.section].splice(routeId, 1);
      }
    }

    const stackSize = state.historyStack?.[currentRoute.section]?.length;

    // remove last route from stack if size reached limit
    if (stackSize === maxStackSize) {
      state.historyStack[currentRoute.section].splice(stackSize - 1, 1);
    }

    state.historyStack?.[currentRoute.section]?.unshift(
      currentRoute.value as IDealHistoryRecord & AdvancedSearchFilter
    );
    localStorage.setItem("historyStack", JSON.stringify(state.historyStack));
  },
  resetHistoryStack(state) {
    state.historyStack = initialHistoryStack;
    localStorage.setItem("historyStack", JSON.stringify(state.historyStack));
  },
  deleteDealFromHistoryStack(state, payload: Record<string, unknown>) {
    state.historyStack[HISTORY_SECTIONS.DEALS] = state.historyStack[
      HISTORY_SECTIONS.DEALS
    ]?.filter((deal) => deal.id !== payload.dealId);

    localStorage.setItem("historyStack", JSON.stringify(state.historyStack));
  },
  setPreventGlobalMessage(state, preventMessage) {
    state.preventGlobalMessage = preventMessage;
  }
};

const actions: ActionTree<IRootState, IRootState> = {
  resetAppState({ commit }) {
    commit("resetHistoryStack");
    commit("applications/resetState");
    commit("funders/resetState");
    commit("clients/resetState");
    const recentDealsIdsResetPayload: {
      key: keyof IDashboardSettings;
      value: IApplication["id"][];
    } = { key: "attributesRecentDealsIds", value: [] };
    commit("options/setSettings", recentDealsIdsResetPayload);
  }
};

const getters: GetterTree<IRootState, IRootState> = {
  globalMessages: (state) => {
    return state.globalMessages;
  },
  globalLoader(state) {
    return state.globalLoader;
  },
  dealsHistoryStack(state) {
    return state.historyStack?.[HISTORY_SECTIONS.DEALS] || [];
  },
  advancedSearchesHistoryStack(state) {
    return state.historyStack?.[HISTORY_SECTIONS.ADVANCED_SEARCHES] || [];
  },
  redirectTo(state) {
    return state.redirectTo;
  }
};

const store = createStore({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth,
    options,
    applications,
    funders,
    clients,
    dashboard,
    stips,
    servicing,
    businesses,
    orchestration,
    workflows,
    scorecards,
    profiles,
    underwriting
  }
});

export default store as ILfVuexStore;
